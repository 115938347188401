<template>
  <v-navigation-drawer
    v-model="close"
    style="background-color: #ffffff"
    app
    light
    :mini-variant.sync="mini"
  >
    <v-list-item style="padding: 4px 10px;">
      <v-list-item-title
        class="subtitle-1 blue-grey--text ml-3 font-weight-bold"
        >Pega</v-list-item-title
      >

      <v-btn icon @click.stop="mini = !mini">
        <v-icon color="#04a9f3">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <div class="side_bar">
      <v-list dense>
        <template v-for="item in linkItem">
          <v-list-item
            :key="item.title"
            v-if="item.rules && $can(item.rules.action, item.rules.subject)"
            router
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="ft"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-for="item in items">
          <v-list-group :key="item.title" :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="ft"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="subItem in item.items">
              <v-list-item
                v-if="
                  subItem.rules &&
                    $can(subItem.rules.action, subItem.rules.subject)
                "
                :key="subItem.title"
                :to="subItem.link"
                router
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="grey--text text--darken-2 ft"
                    style="text-transform: capitalize; font-size: 12px;"
                    v-text="subItem.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </div>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    drawer: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mini: false,
      close: true,
      linkItem: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          rules: {
            action: "view_reports",
            subject: "REPORTS"
          },
          link: { name: "master.dashboard" }
        },
        {
          icon: "people_alt",
          title: "Customers",
          rules: {
            action: "view_customers",
            subject: "CUSTOMERS"
          },
          link: { name: "master.customers" }
        }
      ],
      items: [
        {
          icon: "mdi-border-color",
          title: "Fuel Mgmt",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Sources",
              rules: {
                action: "view_sources",
                subject: "SOURCES"
              },
              link: { name: "master.sources" }
            },
            {
              icon: "mdi-minus-box",
              title: "Products",
              rules: {
                action: "view_fuel",
                subject: "FUEL"
              },
              link: { name: "master.products" }
            },
            {
              icon: "mdi-minus-box",
              title: "Orders",
              rules: {
                action: "view_orders",
                subject: "ORDERS"
              },
              link: { name: "master.orders" }
            }
          ]
        },
        {
          icon: "mdi-basket-minus",
          title: "Lubes",
          items: [
            {
              icon: "mdi-minus-box",
              rules: {
                action: "view_lubes",
                subject: "LUBES"
              },
              title: "List",
              link: { name: "master.lubes" }
            },
            {
              icon: "mdi-minus-box",
              title: "Pending Requests",
              rules: {
                action: "view_pending_lube_requests",
                subject: "LUBES"
              },
              link: { name: "master.lubes.orders.branch" }
            },
            {
              icon: "mdi-minus-box",
              title: "Approved Requests",
              rules: {
                action: "view_approved_lube_requests",
                subject: "LUBES"
              },
              link: { name: "master.lubes.orders.branch.approved" }
            }
          ]
        },
        {
          icon: "local_shipping",
          title: "Deliveries Mgmt",
          rules: {
            action: "view_deliveries",
            subject: "DELIVERIES"
          },
          items: [
            {
              icon: "mdi-minus-box",
              title: "List",
              rules: {
                action: "view_deliveries",
                subject: "DELIVERIES"
              },
              link: { name: "master.deliveries" }
            }
          ]
        },
        {
          icon: "shopping_basket",
          title: "Stock Mgmt",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Products Stock",
              rules: {
                action: "view_fuel_stocks",
                subject: "STOCK"
              },
              link: { name: "master.stock.products.list" }
            },
            {
              icon: "mdi-minus-box",
              title: "Lubes Stock",
              rules: {
                action: "view_lubes_stocks",
                subject: "STOCK"
              },
              link: { name: "master.stock.list" }
            }
          ]
        },
        {
          icon: "verified",
          title: "Sales Approvals",
          rules: {
            action: "view_pending_sales_list",
            subject: "SALES"
          },
          items: [
            {
              icon: "mdi-minus-box",
              title: "Pending Sales",
              rules: {
                action: "view_pending_sales_list",
                subject: "SALES"
              },
              link: { name: "master.sales.pending" }
            }
          ]
        },
        {
          icon: "trending_up",
          title: "Sales Records",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Fuel Sales",
              rules: {
                action: "view_fuel_sales",
                subject: "SALES"
              },
              link: { name: "master.sales.products" }
            },
            {
              icon: "mdi-minus-box",
              rules: {
                action: "view_lube_sales",
                subject: "SALES"
              },
              title: "Lube Sales",
              link: { name: "master.sales.lubes" }
            },
            {
              icon: "mdi-minus-box",
              title: "Shop Sales",
              rules: {
                action: "view_shop_sales",
                subject: "SALES"
              },
              link: { name: "master.sales.shops" }
            }
          ]
        },
        {
          icon: "agriculture",
          title: "Transport Mgmt",
          rules: {
            action: "view_drivers",
            subject: "TRANSPORT"
          },
          items: [
            {
              icon: "mdi-minus-box",
              title: "List",
              rules: {
                action: "view_drivers",
                subject: "TRANSPORT"
              },
              link: { name: "master.transport" }
            }
          ]
        },
        {
          icon: "mdi-sitemap",
          title: "Branches",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Branch",
              rules: {
                action: "view_branches",
                subject: "BRANCHES"
              },
              link: { name: "master.branches" }
            },
            {
              icon: "mdi-minus-box",
              title: " Branch Managers",
              rules: {
                action: "view_branch_managers",
                subject: "BRANCHES"
              },
              link: { name: "master.branch-managers" }
            }
          ]
        },
        {
          icon: "mdi-account-multiple-check",
          rules: {
            action: "view_settings",
            subject: "SETTINGS"
          },
          title: "Settings",
          items: [
            {
              icon: "mdi-minus-box",
              title: "System",
              rules: {
                action: "view_settings",
                subject: "SETTINGS"
              },
              link: { name: "master.settings.system" }
            },
            {
              icon: "mdi-minus-box",
              title: "Users",
              rules: {
                action: "view_users",
                subject: "USER"
              },
              link: { name: "master.users" }
            },
            {
              icon: "mdi-minus-box",
              title: "ACL",
              rules: {
                action: "view_roles",
                subject: "ROLE"
              },
              link: { name: "master.roles" }
            }
          ]
        },
        {
          icon: "bar_chart",
          rules: {
            action: "view_reports",
            subject: "REPORTS"
          },
          title: "Reports",
          items: [
            {
              icon: "mdi-minus-box",
              rules: {
                action: "view_reports",
                subject: "REPORTS"
              },
              title: "Sales & Orders",
              link: { name: "master.reports.overview" }
            },
            {
              icon: "mdi-minus-box",
              rules: {
                action: "view_reports",
                subject: "REPORTS"
              },
              title: "Stock Levels",
              link: { name: "master.stock.levels" }
            },

            {
              icon: "mdi-minus-box",
              rules: {
                action: "view_reports",
                subject: "REPORTS"
              },
              title: "Exports & Downloads",
              link: { name: "master.reports.exports" }
            }
          ]
        }
      ]
    };
  },
  watch: {
    drawer(value) {
      this.close = value;
    }
  },
  methods: {},

  created() {
    this.close = this.drawer;
  }
};
</script>

<style scoped></style>
