var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"ft font-weight-medium elevation-2",attrs:{"app":"","light":"","color":"primary"}},[_c('v-app-bar-nav-icon',{staticStyle:{"color":"#ffffff"},on:{"click":function($event){$event.stopPropagation();return _vm.expand($event)}}}),_c('h3',{staticClass:"mt-1 white--text font"},[_vm._v(" Operations Portal ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('i',{staticClass:"material-icons",style:({
        transform:
          _vm.notifications
            .filter(function (notification) { return notification.unread; })
            .reduce(function (acc, notification) { return (acc += 1); }, 0) > 0
            ? 'rotate(30deg)'
            : 'rotate(0deg)'
      })},[_vm._v("notifications")]),(
        _vm.notifications
          .filter(function (notification) { return notification.unread; })
          .reduce(function (acc, notification) { return (acc += 1); }, 0) > 0
      )?_c('span',{staticClass:"ft font-weight-bold notification-badge"},[_vm._v(_vm._s(_vm.notifications .filter(function (notification) { return notification.unread; }) .reduce(function (acc, notification) { return (acc += 1); }, 0)))]):_vm._e()]),_c('v-menu',{attrs:{"offset-y":"","content-class":"dropdown-menu","transition":"slide-y-transition","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('a',_vm._g({staticClass:"ml-7 mt-2  mb-3"},on),[_c('v-icon',{staticStyle:{"color":"#ffffff"},attrs:{"size":"35","left":""}},[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-card',{attrs:{"tile":""}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.options),function(option,idx){return _c('v-list-item',{key:idx,attrs:{"nudge-right":"50px"},on:{"click":function($event){return _vm.state(option.title)}}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium ma-n1"},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(option.title))])])],1)}),1)],1)],1),_c('LogoutDialog',{attrs:{"state":_vm.dialog('logout')},on:{"close":_vm.close,"logout":_vm.logout}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }