<template>
  <v-app-bar
    app
    light
    color="primary"
    class="ft font-weight-medium elevation-2"
  >
    <v-app-bar-nav-icon
      @click.stop="expand"
      style="color: #ffffff"
    ></v-app-bar-nav-icon>

    <h3 class="mt-1 white--text font">
      Operations Portal
    </h3>
    <v-spacer></v-spacer>
    <v-btn icon color="white">
      <i
        :style="{
          transform:
            notifications
              .filter(notification => notification.unread)
              .reduce((acc, notification) => (acc += 1), 0) > 0
              ? 'rotate(30deg)'
              : 'rotate(0deg)'
        }"
        class="material-icons"
        >notifications</i
      >
      <span
        v-if="
          notifications
            .filter(notification => notification.unread)
            .reduce((acc, notification) => (acc += 1), 0) > 0
        "
        class="ft font-weight-bold notification-badge"
        >{{
          notifications
            .filter(notification => notification.unread)
            .reduce((acc, notification) => (acc += 1), 0)
        }}</span
      >
    </v-btn>

    <v-menu
      offset-y
      content-class="dropdown-menu"
      transition="slide-y-transition"
      min-width="200px"
    >
      <template v-slot:activator="{ on }">
        <a v-on="on" class="ml-7 mt-2  mb-3"
          ><v-icon size="35" style="color: #ffffff" left
            >mdi-account-circle</v-icon
          >
        </a>
      </template>
      <v-card tile>
        <v-list dense>
          <v-list-item
            nudge-right="50px"
            v-for="(option, idx) in options"
            :key="idx"
            @click="state(option.title)"
          >
            <v-list-item-title class="ft font-weight-medium ma-n1"
              ><span class="ft font-weight-medium font-size-sm">{{
                option.title
              }}</span></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <LogoutDialog :state="dialog('logout')" @close="close" @logout="logout" />
  </v-app-bar>
</template>

<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import DialogMixins from "../../mixins/DialogMixins";
export default {
  name: "Appbar",
  components: {
    LogoutDialog: () => import(`../../components/LogoutDialog`)
  },
  mixins: [DialogMixins],
  data: () => ({
    drawer: false,
    //logoutDialog: false,
    passwordDialog: false,
    show: false,
    show1: false,
    show2: false,
    progress: false,
    password: "",
    newPassword: "",
    confirmPassword: "",
    errorMessage: "",
    passwordMessage: "",
    confirmPasswordMessage: "",
    newPasswordMessage: "",
    formHasErrors: false,
    rules: {
      requiredPassword: value => !!value || "Password is required.",
      min: value => value.length >= 6 || "At least 6 characters"
    },
    options: [{ title: "Change Password" }, { title: "Logout" }]
  }),

  computed: {
    ...mapGetters({
      notifications: "settings/getNotifications"
    }),
    form() {
      return {
        password: this.password,
        newPassword: this.newPassword
      };
    },

    passwordConfirmationRule() {
      return () =>
        this.confirmPassword === this.newPassword || "Password must match";
    }
  },

  methods: {
    changePassword() {
      this.progress = true;
      if (this.confirmPassword === this.newPassword) {
        setTimeout(() => {
          axios({
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/entry/change_password`,
            data: {
              current_password: this.password,
              new_password: this.newPassword
            }
          })
            .then(response => {
              if (response.status === 200) {
                this.progress = false;
                this.passwordDialog = false;
                this.logout();
              } else {
                //
              }
            })
            .catch(err => {
              // console.log(err);
              this.passwordMessage =
                err.response != null ? err.response.data.message : err.message;
              if (err.response.status === 401) {
                this.$router.replace({ path: "/login" });
              }
            });
        }, 5000);
      } else {
        // console.log("passwords don't match");
      }
    },
    state(value) {
      switch (value) {
        case "Profile":
          this.$router.push({ name: "master.profile" });
          break;
        case "Change Password":
          this.$router.push({ name: "master.change.password" });
          break;
        case "Logout":
          this.open("logout");
          //this.logoutDialog = true;
          break;
        default:
          break;
      }
    },

    alert() {
      this.$swal("Are you sure you want to log out?", {
        buttons: {
          cancel: true,
          confirm: "Yes"
        }
      }).then(value => {
        if (value) {
          this.logout();
        }
      });
    },
    expand() {
      this.drawer = !this.drawer;
      this.$emit("drawer", this.drawer);
    },

    logout() {
      localStorage.clear();
      this.close("logout");
      this.$router.replace({ path: "/login" });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #ffffff; /* Green */
  border: none;
  color: white;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.button1 {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 45px;
  cursor: pointer;
}
</style>
